<template lang="pug">
.event-listing
  p.caps-title {{ eventData.eventName }}
  p.caps-subtitle {{ eventData.eventDate }}
  img.park-image(:src="require(`@/assets/${eventData.eventImg}.png`)" :alt="eventData.eventImg")
  div(v-if="eventData.eventType == 'tournament'")
    a.square-button(:href="eventData.eventLink" v-if="eventData.eventLink") MORE INFORMATION
    a.square-button(v-else) INFORMATION COMING SOON
  div(v-else)
    p {{ eventData.eventCost }}
    span.tag.is-light Weekly League
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  eventData: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss">
.event-listing {
  background-color: #333;
  padding: 1rem;
  color: #fff;
  position: relative;
  margin-bottom: 40px;
  min-height: 187px;
}
.caps-title {
  font-family: 'Roboto Mono', monospace;
  font-size: 2rem;
  font-weight: 400;
}
.caps-subtitle {
  font-family: 'Roboto Mono', monospace;
  font-size: .8rem;
  font-weight: 400;
}
.square-button {
  background-color: #933;
  color: #ffffff;
  padding: 1rem 2rem;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.park-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 225px;
  margin-top: -20px;
  margin-bottom: -20px;
}
@media only screen and (max-width: 768px) {
  .event-listing {
    text-align: center;
  }
  .caps-title {
    font-size: 1rem;
  }
  .park-image {
    position: relative;
    display: block;
    width: auto;
    height: 200px;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .square-button {
    background-color: #933;
    color: #ffffff;
    padding: .5rem;
    display: block;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
</style>
