<template lang="pug">
.event
  //- .article.message.is-link
    .message-body
      .columns.is-mobile
        .column
          h2.is-size-3 Herrin Memorial Open
          span.is-size-7 Presented By Discmania
          p PDGA Sanctioned event in Herrin, IL
          p Saturday April 9th, 2022
          p Join us for Herrin's first disc golf tournament!
          p 2 rounds of 18 holes
          hr
          .buttons
            a.button.is-primary(href="https://www.discgolfscene.com/tournaments/Herrin_Memorial_Open_2022" target="_blank") View / Register
            router-link.button.is-link(to="/caddie") Caddie Book
  event-listing(v-for="eventData in eventListings" :key="eventData.eventId" :eventData="eventData")
</template>

<script setup>
import { ref } from 'vue'
import EventListing from '@/components/EventListing'

const eventListings = ref([
  // {
  //   eventId: 16,
  //   eventName: 'Spider House Friday Night',
  //   eventDate: 'Friday, April 28th at 6:00pm ($5)',
  //   eventLink: '',
  //   eventImg: 'spider-house',
  //   sanctioned: true,
  //   eventType: 'league'
  // },
  // {
  //   eventId: 4,
  //   eventName: 'Annabelle Fest 2023',
  //   eventDate: 'Saturday, June 11, 2023',
  //   eventLink: 'https://www.discgolfscene.com/tournaments/Annabelle_Fest_2023_Disc_Golf_Tournament',
  //   eventImg: 'anna',
  //   sanctioned: false,
  //   eventType: 'tournament'
  // },
  // {
  //   eventId: 8,
  //   eventName: 'Spider House Friday Night',
  //   eventDate: 'Friday, June 16th at 6:00pm ($5)',
  //   eventLink: '',
  //   eventImg: 'spider-house',
  //   sanctioned: false,
  //   eventType: 'league'
  // },
  // {
  //   eventId: 7,
  //   eventName: 'Fox Town Showdown',
  //   eventDate: 'Saturday, July 29, 2023',
  //   eventLink: 'https://www.discgolfscene.com/tournaments/1st_Annual_Fox_Town_Showdown_2023',
  //   eventImg: 'fox-prarie',
  //   sanctioned: true,
  //   eventType: 'tournament'
  // },
  // {
  //   eventId: 5,
  //   eventName: 'Trilogy Skillz Challenge 2023',
  //   eventDate: 'Sunday, September 10, 2023',
  //   eventLink: '',
  //   eventImg: 'cobden',
  //   sanctioned: false,
  //   eventType: 'tournament'
  // },
  // {
  //   eventId: 6,
  //   eventName: 'Veterans For Vets',
  //   eventDate: 'Sunday, November 12, 2023',
  //   eventLink: '',
  //   eventImg: 'cobden',
  //   sanctioned: false,
  //   eventType: 'tournament'
  // }

])
</script>
